import { Component, OnInit, Input, Optional, Inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { HttpStatusCodeService } from './core/http-status-code.service';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { RESPONSE } from 'src/express.tokens';
import { Response } from 'express';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404">
      <app-header
        [cssClass]="'type--404'"
        [inverted]="true"
        (inView)="(true)"
        [subtitle]="'error-404-text' | fromDictionary"
        [title]="'error-404-title' | fromDictionary"
      ></app-header>
    </article>
  `,
  styles: [
    '.error-404__content { color:black; position: absolute; bottom: 80px;left:50%;text-align:center;transform: translateX(-50%); z-index:16; }',
  ],
})
export class NotFoundComponent implements OnInit {
  code = 404;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Optional() @Inject(RESPONSE) private response: Response,
    private router: Router
  ) {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      if (this.response) {
        this.response.status(this.code);
      }
    }
  }
}
